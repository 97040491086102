import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { httpss } from '../utils/host';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Notification from '../components/layout/Noti'
import { GlobalState } from '../store/GlobalState';
import Footer from '../components/home/footer';
import Header from '../components/home/header';
import AddVanBan from '../components/layout/AddVanBan';
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
// import {LinhVucData, NamBanHanhData, NoiBHData} from '../assets/data/select';
import dataSelectSearch from '../assets/data/select.json';
import EditsVanBan from '../components/layout/EditVanBan';
import { MdFirstPage, MdLastPage } from "react-icons/md";

export default function HomeVanBan() {
  const state = useContext(GlobalState);
  // console.log("home",state)
  const LinhVucList = state?.dataList?.dataList[0]?.LinhVucs.map(item => item.LinhVucName);
  const NoiBHList = state?.dataList?.dataList[0]?.NoiBHs.map(item => item.NoiBHName);
  const LoaiVBList = state?.dataList?.dataList[0]?.LoaiVBs.map(item => item.LoaiVBName);
  const [ isVanBan, setIsVanBan ] = useState(false);
  const navigator = useNavigate();

  const [notification, setNotification] = useState(null);
  const showNotification = (message) => {setNotification({ message })}
  const handleCloseNotification = () => {setNotification(null)}

  const [currentPage, setCurrentPage] = useState(1); // Trang hiện tại
  const [rowsPerPage, setRowsPerPage] = useState(20); // Số dòng mỗi trang
  const [totalRecords, setTotalRecords] = useState(0); // Tổng số bản ghi

  const [ addVanBan, setAddVanBan ] = useState(false);
  const handleAddVanBan = () => {setAddVanBan(true)}
  const handleCloseAddVanBan = () => {setAddVanBan(false)}

  const [vanbanEdit, setVanbanEdit] = useState();
  const [Edit, setEdit] = useState(false);
  const handleEditVanBan = (item) => {setVanbanEdit(item);setEdit(true)}
  const handleCloseEdit = () => {setEdit(false)};

  const [formData, setFormData] = useState({
    LinhVucName:'', 
    NamBanHanh:'',
    NoiBHName:'',
    search:''
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  
  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value));
    setCurrentPage(1); // Reset về trang đầu
  };

  const [vanban, setVanban] = useState([]);

  function handleInput(e) {
    // setState(prev => ({...prev,[e.target.name]:e.target.value}))
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]:e.target.value,
    }));
  }

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleResetSearch = () => {
    setFormData({
      LinhVucName: '',
      NamBanHanh: '',
      NoiBHName: '',
      search: '',
    });
    setCurrentPage(1);
    setRowsPerPage(20);
    sessionStorage.removeItem("searchData");
  };

const handleSearch = async () => {
  try {
    const url = `${httpss}/app/users/vanbans`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify({
        ...formData,
        limit: rowsPerPage,
        offset: (currentPage - 1) * rowsPerPage,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      setVanban(data.rows);
      setTotalRecords(data.count);

      // Lưu trạng thái tìm kiếm vào sessionStorage
      sessionStorage.setItem(
        "searchData",
        JSON.stringify({
          formData,
          currentPage,
          rowsPerPage,
          scrollPosition: window.scrollY, // Vị trí cuộn hiện tại
        })
      );
    } else {
      console.error("Request failed:", response.status);
    }
  } catch (error) {
    console.error("Error:", error);
  }
};

// // Gọi lại API khi thay đổi số dòng mỗi trang hoặc trang hiện tại
// useEffect(() => {
//   handleSearch();
// }, [rowsPerPage, currentPage]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  const handleDeleteVanBan = async (item) => {
    if(!window.confirm(`Bạn có chắc chắn muốn xóa văn bản số hiệu "${item.KyHieu}" không?`)) return
    try {
      const url = `${httpss}/app/users/delete_vanban`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({ id:item.id }),
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data)
        handleSearch();
      } else {
        console.error('Request failed:', response.status);
        showNotification('Xoá văn bản khó');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleSearch();
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [formData, state, currentPage, rowsPerPage]);

  useEffect(() => {
    // Khôi phục trạng thái tìm kiếm từ sessionStorage
    const savedSearchData = JSON.parse(sessionStorage.getItem("searchData"));
    if (savedSearchData) {
      setFormData(savedSearchData.formData || {});
      setCurrentPage(savedSearchData.currentPage || 1);
      setRowsPerPage(savedSearchData.rowsPerPage || 20);
      window.scrollTo(0, savedSearchData.scrollPosition || 0);
    }
  }, []);

  return (
    <div>
      <Header/>
        <div className='min-h-[50vh]'>
          <div className='container mx-auto flex flex-col gap-2 py-5'>
            <div className='p-2 border border-[#0266B4] rounded'>
              <p className='text-xl text-gray-700 uppercase'>tìm kiếm văn bản</p>

              <div className='grid grid-cols-1 lg:grid-cols-3 gap-2'>
                <div class="input-container">
                  <select onChange={handleSelectChange} value={formData.LinhVucName} id="LinhVucName" name="LinhVucName" class="select-field">
                    <option value="">-- Tất cả --</option>
                    {LinhVucList?.map((item, index) => (
                      <option value={item}>{item}</option>
                    ))}
                  </select>
                  <label for="LinhVucName" class="input-label">Lĩnh vực</label>
                </div>
                <div class="input-container">
                  <select onChange={handleSelectChange} value={formData.NoiBHName} id="NoiBHName" name="NoiBHName" class="select-field">
                    <option value="">-- Tất cả --</option>
                    {NoiBHList?.map((item, index) => (
                      <option value={item}>{item}</option>
                    ))}
                  </select>
                  <label for="NoiBHName" class="input-label">Đơn Vị Ban Hành</label>
                </div>

                <div class="input-container">
                  <select onChange={handleSelectChange} value={formData.NamBanHanh} id="NamBanHanh" name="NamBanHanh" class="select-field">
                    <option value="">-- Tất cả --</option>
                    {dataSelectSearch.NamBH.map((item, index) => (
                      <option value={item}>{item}</option>
                    ))}
                  </select>
                  <label for="NamBanHanh" class="input-label">Năm Ban Hành</label>
                </div>

                <div class="input-container">
                  <input onChange={handleInput} value={formData.search} type="search" name="search" class="input-field" placeholder=" "  required />
                  <label htmlFor="search" class="input-label">Tìm kiếm trích yếu</label>
                </div>
                <div className='flex justify-center items-center'>
                  <button onClick={handleSearch} className='bg-[#0678BD] hover:bg-[#0678BD]/80 text-white rounded py-3 px-4 w-full font shadow uppercase font-bold'>Tìm kiếm</button>
                </div>
                <div className='flex justify-center items-center'>
                  <button onClick={handleResetSearch} className='bg-[#0678BD] hover:bg-[#0678BD]/80 text-white rounded py-3 px-4 w-full font shadow uppercase font-bold'>xóa bộ lộc</button>
                </div>
              </div>
            </div>

            <div className='flex flex-col h-[70vh] max-md:h-[50vh] p-2 border border-[#0266B4] rounded '>
              <div className='flex justify-between items-center my-2'>
                <p className='text-xl text-gray-700 uppercase'>Văn bản</p>
                {state?.userInfos?.userInfos[0].Role === 1 ? (
                  <div className='flex justify-center items-center gap-2'>
                    <a href='https://hunghaugroup.sharepoint.com/HHH.HungHauHoldings/HHOTaiLieuNoiBo' 
                      target='_blank' 
                      className='bg-[#4AA748] hover:bg-[#4AA748]/80 hover:text-underline text-white rounded py-1 px-10 justify-items-end font shadow font-bold uppercase'>Văn bản HHH SG</a>
                    <button className='bg-[#0678BD] hover:bg-[#0678BD]/80 text-white rounded py-1 px-10 justify-items-end font shadow uppercase font-bold' onClick={handleAddVanBan}>Thêm văn bản</button>
                  </div>
                ):(
                  null
                )}
                {addVanBan && <AddVanBan onClose={handleCloseAddVanBan} data={vanban} />}
                {Edit && <EditsVanBan onClose={handleCloseEdit} data={vanbanEdit} />}
              </div>
              <div className='flex-grow overflow-auto'>
                <table className='w-full'>
                  <thead className='bg-gray-300 text-gray-700 sticky -top-0.5 z-0'>
                    <tr>
                      <th className='border text-center font-bold p-1 text-nowrap'>STT</th>
                      <th className='border text-center font-bold p-1 text-nowrap'>Ký hiệu</th>
                      <th className='border text-center font-bold p-1 text-nowrap'>Ngày ban hành</th>
                      <th className='border text-center font-bold p-1 text-nowrap w-full'>Trích yếu</th>
                      {state?.userInfos?.userInfos[0].Role === 1 ? (
                        <th className='border text-center font-bold p-1 text-nowrap'>Hành động</th>
                      ):(
                        null
                      )}
                    </tr>
                  </thead>
                  {Array.isArray(vanban) && vanban.map((item, index) => (
                    <tbody>
                        <tr key={index} className='hover:bg-gray-100 group'>
                          <td className='border p-1 group-hover:text-blue-600 cursor-pointer' onClick={() => navigator(`/details?id=${item.id}`)}>{index + 1}</td>
                          <td className='border p-1 group-hover:text-blue-600 cursor-pointer text-nowrap' onClick={() => navigator(`/details?id=${item.id}`)}>{item.KyHieu}</td>
                          <td className='border text-center p-1 group-hover:text-blue-600 cursor-pointer' onClick={() => navigator(`/details?id=${item.id}`)}>{formatDate(item.NgayBanHanh)}</td>
                          <td className='border p-1 group-hover:text-blue-600 cursor-pointer' onClick={() => navigator(`/details?id=${item.id}`)}>{item.TrichYeu}</td>
                          {state?.userInfos?.userInfos[0].Role === 1 ? (
                            <td className='border p-1 group-hover:text-blue-600 cursor-pointer'>
                              <button className='bg-[#0678BD] hover:bg-[#0678BD]/80 text-white rounded py-1 px-10 justify-items-end font shadow uppercase font-bold' onClick={() => {handleDeleteVanBan(item)}}><MdDeleteOutline/></button>
                            </td>
                          ):(
                            null
                          )}

                        </tr>
                    </tbody>
                  ))}
                </table>
              </div>
              <div className='flex justify-end pt-1'>
              <div className="flex gap-2">
                <div className="flex gap-2">
                  <div className="flex gap-2 overflow-auto">
                    {Array.from({ length: Math.ceil(totalRecords / rowsPerPage) })
                      .map((_, index) => index + 1)
                      .filter((page) => {
                        const totalPages = Math.ceil(totalRecords / rowsPerPage);
                        return (
                          page === 1 ||
                          page === totalPages ||
                          (page >= currentPage - 1 && page <= currentPage + 1)
                        );
                      })
                      .map((page, index, filteredPages) => (
                        <React.Fragment key={page}>
                          {index > 0 && page !== filteredPages[index - 1] + 1 && (
                            <span className="px-3">...</span>
                          )}
                          <button
                            className={`px-3 border rounded ${
                              currentPage === page ? "bg-[#0678BD] hover:bg-[#0678BD]/80 text-white rounded px-1 justify-items-end font shadow uppercase font-bold" : ""
                            }`}
                            onClick={() => handlePageChange(page)}
                          >
                            {page}
                          </button>
                        </React.Fragment>
                      ))}
                  </div>
                </div>
                <div className='border rounded'>
                  <select id="rowsPerPage" value={rowsPerPage} onChange={handleRowsPerPageChange} className="" >
                    <option value="20">20 / trang</option>
                    <option value="50">50 / trang</option>
                    <option value="100">100 / trang</option>
                    <option value="200">200 / trang</option>
                  </select>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      <Footer/>
      </div>
  );
}
